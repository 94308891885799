.runtime_filter_main_box {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  gap: 15px;
}

.runtime_filter_child_box {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.runtime_filter_title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.8px;
  text-align: left;
  width: calc(100% - 10px);
}

.runtime_filter_textBox span {
  border-radius: 8px;
}

.runtime_filter_dropdown span {
  border-color: #cccccc;
  height: 48px;
  background: white;
  border-radius: 8px;
}

.runtime_filter_dropdown span:hover {
  background: white;
}

.runtime_filter_dropdown_item:focus {
  box-shadow: none;
}

.runtime_filter_dropdown_item
  .k-input-button
  .k-icon.k-i-caret-alt-down:before {
  content: none !important;
}

.runtime_filter_dropdown_item .k-input-button .k-icon.k-svg-i-caret-alt-down {
  background-image: url("/src/assets/common/arrowDown.svg") !important;
  background-repeat: no-repeat !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-position: -2px center;
}

.runtime_filter_dropdown_item
  .k-input-button
  .k-icon.k-svg-i-caret-alt-down
  svg {
  display: none;
}

.runtime_filter_clear_button {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: 0.03em;
  text-align: center;
  text-decoration-line: underline;
  text-decoration-style: solid;
  color: blue;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 150px;
}

.runtime_filter_approve_button {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: #f04a00;
  border-radius: 8px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.8px;
  text-align: center;
  padding: 0px 20px;
  cursor: pointer;
}

.Runtime_detail_main_box {
  height: calc(100% - 75px);
}

.Runtime_detail_child_box {
  display: flex;
  height: 100%;
}

.Runtime_detail_left_box {
  flex: 1 1 64%;
  padding: 20px 0px 0px 20px;
}

.Runtime_detail_back {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 28.13px;
  text-align: left;
  color: #73007d;
  display: flex;
  gap: 20px;
  align-items: center;
  cursor: pointer;
}

.Runtime_detail_child_box_main_detail_box {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  height: calc(100% - 60px);
  padding-right: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.Runtime_detail_child_box_main_detail_items {
  display: flex;
  gap: 20px;
}

.Runtime_detail_child_box_main_detail_items_title {
  width: 230px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.8px;
  text-align: left;
  color: #333333;
}

.Runtime_detail_center_item {
  align-items: center;
  display: flex;
}

.detail_padding_bottom {
  padding-bottom: 15px;
  padding-top: 15px;
}

.Runtime_detail_child_box_main_detail_items_splitter {
  width: 1px;
  background: #cccccc;
}

.Runtime_detail_child_box_main_detail_items_subdetail {
  flex: 1;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #333333;
}

.Runtime_detail_right_box {
  flex: 1 1 35%;
  overflow: scroll;
  background: #f5f7fa;
  padding: 20px;
  max-width: 350px;
  min-width: 270px;
}

.Runtime_detail_right_status_box {
  background: white;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.Runtime_detail_right_box_status_title {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #333333;
}

.Runtime_detail_right_box_status_subdetail {
  background: #36d49b;
  color: white;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.8px;
  text-align: center;
  border-radius: 8px;
  max-width: 110px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Runtime_detail_right_box_action_main_box {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: white;
  border-radius: 8px;
  padding: 20px;
}

.Runtime_detail_right_box_action_title {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #333333;
}

.Runtime_detail_right_box_action_edit {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.8px;
  text-align: center;
  color: #666666;
  border: 1px solid #666666;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 20px;
  max-width: 200px;
  cursor: pointer;
}

.Runtime_detail_right_box_action_delete {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.8px;
  text-align: center;
  color: #666666;
  border: 1px solid #666666;
  /* height: 48px; */
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 20px;
  max-width: 220px;
  cursor: pointer;
}

.audit_bar_hide .k-stepper .k-progressbar-vertical {
  display: none;
}

.Runtime_detail_route_main_box {
  background-color: #f5f7fa;
  padding: 15px;
  margin-top: 50px;
}

.Runtime_detail_route_first_child_box {
  display: flex;
  gap: 10px;
  align-items: center;
}

.Runtime_detail_route_first_child_box_image {
  height: 48px;
  width: 48px;
}

.Runtime_detail_route_first_child_box_detail_box {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.Runtime_detail_route_first_child_box_detail_item_first {
  background-color: #73007d;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  height: 30px;
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.Runtime_detail_route_first_child_box_detail_item_second {
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
  line-height: 20.8px;
  letter-spacing: 0%;
  color: #333333;
}

.Runtime_detail_route_second_child_box {
  display: flex;
  gap: 10px;
  margin: 15px 0px;
}

.Runtime_detail_route_second_child_box_arrow_main_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48px;
}

.Runtime_detail_route_second_child_box_arrow_tail {
  width: 8px;
  height: calc(100% - 24px);
  background-color: #73007d;
}

.Runtime_detail_route_second_child_box_arrow_head {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 27px;
  color: #73007d;
  width: 24px;
  height: 24px;
}

.Runtime_detail_route_second_detail_box {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 100px;
}

.Runtime_detail_route_second_detail_box_item_box {
  display: flex;
  gap: 15px;
}

.Runtime_detail_route_second_detail_box_item_box_first {
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0%;
  color: #666666;
  min-width: 55px;
}

.Runtime_detail_route_second_detail_box_item_box_second {
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0%;
  color: #333333;
}

.runtime_filter_dropdown .k-input-value-text {
  display: flex;
  align-items: center;
}
