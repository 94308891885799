.dr-secure-details-wrapper {
  display: flex;
  flex-flow: row wrap;
  /* width: calc(100vw - 240px); */
  width: 95%;
  justify-content: space-between;
  gap: 25px;
  background-color: #f5f7fa;
  margin-left: 20px;
  margin-top: 20px;
}

.dr-secure-details-right {
  width: 30%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  gap: 24px;
  padding-top: 24px;
  padding-right: 24px;
}

.dr-secure-details-left {
  flex-grow: 1;
  background-color: #fff;
  width: 65%;
}

.dr-secure-details-status-wrapper,
.dr-secure-details-actions-wrapper,
.dr-secure-details-audits-wrapper {
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
}

.dr-secure-details-status-title,
.dr-secure-details-actions-title,
.dr-secure-details-audits-title,
.dr-secure-details-updates-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: #333333;
  margin-bottom: 17px;
}

.dr-secure-details-status-text {
  float: left;
  height: 20px;
  background: #36d49b;
  border-radius: 8px;
  padding: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dr-secure-details-actions-button-list {
  display: flex;
  flex-flow: column;
  gap: 17px;
}

.dr-secure-details-actions-button {
  padding-top: 13.5px;
  padding-bottom: 13.5px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff;
  border: 1px solid #666666;
  border-radius: 8px;
  float: left;
  cursor: pointer;
  align-self: self-start;
  user-select: none;
}

.dr-secure-details-actions-button img {
  margin-left: 8px;
}

.dr-secure-details-actions-button span {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #666666;
}

.dr-secure-details-content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding-left: 24px;
  padding-top: 24px;
  padding-right: 24px;
}

.dr-secure-details-content-header-text {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #781d7e;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dr-secure-details-content-header-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 89px;
  height: 37px;
  background: #ffffff;
  border: 1px solid #666666;
  border-radius: 8px;
  float: right;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #666666;
  cursor: pointer;
}

.dr-secure-details-content-details {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  margin-top: 38px;
}

.dr-secure-details-content-details-left {
  width: 25%;
  border-right: 1px solid #cccccc;
  margin-right: 26px;
  padding-right: 26px;
  padding-left: 32px;
}

.dr-secure-details-content-details-left-label {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #333333;
  height: 21px;
}

.dr-secure-details-content-details-left-label.normal {
  margin-bottom: 16px;
}

.dr-secure-details-content-details-left-label.description {
  margin-bottom: 58px;
}

.dr-secure-details-content-details-left-label.attachment {
  margin-bottom: 105px;
}

.dr-secure-details-content-details-right {
  flex-grow: 1;
  width: 60%;
  padding-right: 26px;
}

.dr-secure-details-content-details-right-label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #333333;
}

.dr-secure-details-content-details-right-label.normal {
  height: 19px;
  margin-bottom: 16px;
}

.dr-secure-details-content-details-right-label.description {
  height: 68px;
  margin-bottom: 19px;
}

.dr-secure-details-content-details-right-label.attachment {
  height: 105px;
  margin-bottom: 19px;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.dr-secure-details-content-details-hr {
  border-top: 1px solid #cccccc;
  margin-top: 24px;
  margin-bottom: 24px;
}

.dr-secure-details-updates-wrapper {
  /* padding-left: 32px;
  padding-right: 32px; */
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
  color: #333333;
}

.dr-secure-details-updates-single-username {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

.dr-secure-details-updates-single-div {
  margin-bottom: 8px;
}

.dr-secure-details-updates-single-role {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-left: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  background: #781d7e;
  border-radius: 8px;
}

.dr-secure-details-updates-single-datetime {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #666666;
}

.dr-secure-details-updates-single-description {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

.dr-secure-details-updates-single-div-wrapper {
  padding-left: 16px;
  padding-top: 27px;
  padding-right: 16px;
  padding-bottom: 27px;
  margin-bottom: 1px;
  background: #f5f7fa;
}

.dr-secure-details-updates-input-wrapper {
  margin-top: 1px;
  padding: 16px;
  background: #f5f7fa;
}

.dr-secure-details-updates-input {
  height: 100px !important;
}

.dr-secure-details-content-details-input-description {
  height: 80px !important;
}

.dr-secure-details-updates-input-wrapper-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #333333;
  margin-top: 0px;
}

.dr-secure-details-updates-input-wrapper-btn,
.dr-secure-details-updates-input-wrapper-btn:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 82px;
  height: 48px;
  background: #eb6e1f;
  border-radius: 8px;
  margin-top: 16px;
  margin-left: auto;
  cursor: pointer;
  color: #fff;
}

.dr-secure-details-content-header-btn-save,
.dr-secure-details-content-header-btn-save:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 82px;
  height: 48px;
  background: #eb6e1f;
  border-radius: 8px;
  cursor: pointer;
  color: #fff;
}

.dr-secure-details-update-loader {
  display: flex;
  justify-content: center;
  height: 100px;
  align-items: center;
}

.dr-secure-details-status-loader,
.dr-secure-audits-status-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dr-secure-details-updates-list-wrapper {
  max-height: 200px;
  overflow-y: auto;
}

.dr-secure-details-status-dialog-title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin: 30px 20px 20px 20px;
}

.dr-secure-details-status-dialog-dropdown-value {
  background: #ffffff !important;
  border: 1px solid #e4e1de !important;
  border-radius: 8px;
  height: 48px;
}

.dr-secure-details-status-dialog-dropdown {
  margin: 10px 20px 20px 20px;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #000000;
}

.dr-secure-details-status-dialog {
  max-width: 390px;
  min-width: 200px;
}

.dr-secure-details-status-dialog-button,
.dr-secure-details-status-dialog-button:hover {
  text-align: center;
  width: 82px;
  height: 48px;
  background: #eb6e1f;
  border-radius: 8px;
  cursor: pointer;
  color: #fff;
}

@media (max-width: 768px) {
  .dr-secure-details-status-dialog {
    max-width: none;
    width: 100%;
  }

  .dr-secure-details-status-dialog-button {
    max-width: none;
    width: 92%;
  }
}

.dr-secure-mark-as-duplicate-dialog {
  max-width: 800px;
  min-width: 600px;
  max-height: 500px;
  min-height: 300px;
}

.dr-secure-mark-as-duplicate-updates-input {
  height: 250px !important;
}

.dr-secure-share-updates-input{
  height: 50px !important;
}

.dr-details__body {
  margin-top: 20px;
}

.dr-details-info {
  font-size: 14px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--antiflash-white);
}

.dr-details-info > .dr-details-info__item {
  width: 100%;
  display: flex;
  column-gap: 20px;
}

.dr-details-info > .dr-details-info__item > .dr-details-info__desc {
  width: 25%;
  padding: 10px 0px;
  font-weight: 500;
  border-right: 2px solid var(--antiflash-white);
}

.dr-details-info > .dr-details-info__item > .dr-details-info__value {
  width: 75%;
  padding: 10px 10px;
  text-align: justify;
}

.dr_secure_detail_right_box_action_main_box {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: white;
  border-radius: 8px;
  padding: 20px;
}

.dr_secure_detail_right_box_action_title {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #333333;
}

.audit_bar_hide .k-stepper .k-progressbar-vertical {
  display: none;
}