.container {
    width: 100%;
}

.filter_main_box {
    display: flex;
    gap: 20px;
    margin: 0px 20px 20px;
    align-items: end;
}

.filter_child_box {
    flex: 1;
}

.k-i-arrow-chevron-down {
    color: #73007D;
}

.filter_child_box .k-dropdownlist {
    min-height: 48px;
    background-color: white;
}

.filter_child_box .react-datepicker-wrapper {
    width: 100%;
}

.filter_button {
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    background: #F04A00;
    min-height: 48px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
}

.filter_title {
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: left;
    margin-bottom: 10px;
}

.persona_detail_main_box {
    /* margin: 20px; */
    height: calc(100% - 75px);
}

.persona_detail_child_box {
    display: flex;
    /* gap: 20px; */
    /* margin-top: 20px; */
    /* height: calc(100% - 55px); */
    height: 100%;
}

.persona-margin-top {
    margin-top: 40px;
}

.persona_detail_right_box {
    flex: 1 1 35%;
    overflow: scroll;
    background: #F5F7FA;
    padding: 20px;
    max-width: 350px;
    min-width: 270px
}

.persona_detail_right_status_box {
    background: white;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.persona_detail_right_box_status_title {
    font-size: 14px;
    font-weight: 600;
    line-height: 19.6px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #333333;
}

.persona_detail_right_box_status_subdetail {
    background: #36D49B;
    color: white;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    border-radius: 8px;
    max-width: 110px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.persona_detail_right_box_action_main_box {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: white;
    border-radius: 8px;
    padding: 20px;
}

.persona_detail_right_box_action_title {
    font-size: 14px;
    font-weight: 600;
    line-height: 19.6px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #333333;
}

.persona_detail_right_box_action_edit {
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    color: #666666;
    border: 1px solid #666666;
    /* height: 48px; */
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px 20px;
    max-width: 200px;
    cursor: pointer;
}

.persona_detail_right_box_action_delete {
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    color: #666666;
    border: 1px solid #666666;
    /* height: 48px; */
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px 20px;
    max-width: 220px;
    cursor: pointer;
}

.persona_detail_right_box_action_edit:hover,
.persona_detail_right_box_action_delete:hover {
    background-color: #f7f4f4;
}

.persona_detail_left_box {
    flex: 1 1 64%;
    /* overflow: scroll; */
    padding: 20px 0px 0px 20px
}

.persona_detail_back {
    font-size: 24px;
    font-weight: 700;
    line-height: 28.13px;
    text-align: left;
    color: #73007D;
    display: flex;
    gap: 20px;
    align-items: center;
    cursor: pointer;
}


.persona_detail_child_box_main_detail_box {
    display: flex;
    flex-direction: column;
    height: calc(100% - 60px);
    padding-right: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 20px;
}

.persona_detail_child_box_main_detail_items {
    display: flex;
    gap: 20px;
    /* min-height: 55px; */
}

.persona_detail_child_box_main_detail_items_title {
    width: 230px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: left;
    color: #333333;
}

.persona_detail_center_item {
    align-items: center;
    display: flex;
}

.detail_padding_bottom {
    padding-bottom: 15px;
    padding-top: 15px;
}

.persona_detail_child_box_main_detail_items_splitter {
    width: 1px;
    background: #CCCCCC;
}

.persona_detail_child_box_main_detail_items_subdetail {
    flex: 1;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #333333;
}

.persona_detail_sender_by {
    color: #781d7e;
    padding: 15px 0px;
    font-weight: 700;
}

.persona_update_upload_button_box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 50px;
    border: 1px solid #73007D;
    border-radius: 4px;
    padding: 0px 20px;
    margin-top: 10px;
    color: #73007D;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    cursor: pointer;
}

.persona_update_upload_button_box:hover {
    border: 1px solid #FFFFFF;
    background-color: #73007D;
    color: #FFFFFF;
}

.persona_update_file_main_box {
    position: relative;
    display: inline-block;
    margin-top: 10px;
    height: 100%;
}


.persona_update_file_main_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
}


.persona_update_file_child_box_delete_icon {
    position: absolute;
    top: 2px;
    right: -5px;
    font-size: 24px;
    color: #FFFFFF;
    background-color: #73007D;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
}


.persona_update_delete_row {
    font-size: 24px;
    color: #EB7930;
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
}

.persona_update_file_image {
    max-height: 320px;
    width: auto;
    object-fit: cover;
}

.persona_update_file_child_box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.persona_update_file_child_box_text {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: left;
}

.persona_update_autosaved_icon {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    border: 1px solid #E7ECEF;
    border-radius: 8px;
    background: #E7ECEF;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    color: #666666;
    gap: 8px;
}

.persona_update_item_main_box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.persona_update_item_main_box_title {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: left;
    color: black;
}

.persona_update_item_main_box_field {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
}

.tutorial_dropdown {
    height: 45px;
    background-color: white;
}

.persona_update_item_main_box_title_span {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #666a6c;
}

.persona_update_scroll_box {
    height: calc(100% - 150px);
    overflow: scroll;
    padding: 0px 20px;
}

.persona_detail_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    /* margin: 20px 20px 0px 20px; */
}


.persona_update_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    margin: 20px 20px 0px 20px;
}

.persona_update_back {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.13px;
    text-align: left;
    color: #73007D;
    display: flex;
    gap: 20px;
    align-items: center;
    cursor: pointer;
}

.tutorial_tab_content {
    padding: 20px 0px;
}


.persona_update_footer_main_box {
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: space-between;
    bottom: 0;
    width: calc(100% - 40px);
    background: white;
    box-shadow: 0px -1px 0px 0px #00000040;
    padding: 15px 20px;
}


.persona_update_error_text {
    margin-top: 10px;
    font-style: italic;
    color: red;
    font-size: 12px;
    min-height: 20px;
}

.tutorial-cancel-button {
    background-color: #fff;
    border: 1px solid #333;
    height: 40px;
}

.tutorial-submit-button {
    background-color: #eb6e1f;
    border: "1px solid #EB6E1F";
    color: #fff;
    height: 40px;
}

.tutorial-submit-button:hover {
    background-color: #eb6e1f;
}


.persona_update_footer_button_previous {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #F04A00;
    border-radius: 8px;
    padding: 0px 24px;
    height: 48px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    background: white;
    color: #F04A00;
    cursor: pointer;
}

.persona_update_footer_button_publish {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #73007D;
    border-radius: 8px;
    padding: 0px 24px;
    height: 48px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    background: #73007D;
    color: white;
    cursor: pointer;
}

.persona_update_footer_button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #F04A00;
    border-radius: 8px;
    padding: 0px 24px;
    height: 48px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    color: white;
    background: #F04A00;
    cursor: pointer;
}

.persona_update_footer_button.disabled {
    border: 1px solid #CCCCCC;
    background: #CCCCCC;
    color: #666666;
    cursor: not-allowed;
    opacity: 0.6;
}

.persona_update_review_main_box {
    display: flex;
    gap: 10px;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;

}


.editBtn {
   font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #666666;
    padding: 5px 25px;
    border-radius: 8px;
    gap: 10px;
    cursor: pointer;
}


/* .editBtn:hover {
    background: #F04A00;
    opacity: 0.7;
} */

.editBtn-label {
    font-weight: 600;
    color: #000000;
    font-size: 16px;
    width: 31px;
    font-style: normal;
    line-height: 130%;
    padding: 0px 8px;
}

.persona_detail_edit {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #666666;
    padding: 5px 25px;
    border-radius: 8px;
    gap: 10px;
    cursor: pointer;
}


.clinic_filter_main_box {
    min-width: 300px;
    min-height: 400px;
    /* padding: 20px; */
}

.clinic_filter_cross_icon {
    position: absolute;
    right: 0px;
    color: #73007D;
    cursor: pointer;
}

.clinic_filter_header_box {
    padding: 40px 20px 0px;
    display: flex;
    justify-content: space-between;
}

.clinic_filter_header_title {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
}

.clinic_filter_header_clear_filter {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.03em;
    text-align: left;
    text-decoration: underline;
    color: #F04A00;
    cursor: pointer;
}

.clinic_filter_detail_main_box {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    height: calc(100vh - 200px);
    overflow: scroll;
    padding: 0px 20px 20px;
}


.clinic_filter_detail_title {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: left;
    color: #333333;
}

.clinic_filter_radio_button_main_box {
    display: flex;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    justify-content: space-between;
    color: #333333;
}

.persona_update_upload_image_box {
    background-color: white;
    border-radius: 10px;
    margin-top: 10px;
    padding: 10px;
    box-sizing: border-box;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}


.tutorial_delete_row_main_box {
    display: flex;
}

.tutorial_delete_row {
    padding: 5px;
    box-sizing: border-box;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}



.persona_update_upload_main_box {
    display: flex;
    flex-direction: column;
    width: 100%;
}


.row-container {
    gap: 20px;

}

.margin-bottom {
    margin-bottom: 20px;
}

.tutorial-row {
    display: flex;
    gap: 10px;
    padding: 15px 10px 15px 15px;
    margin: 0px;
    background-color: #F5F7FA;
    margin-bottom: 20px;
}



.tutorial_add_row_button {
    margin: 10px 0px 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 300px;
    border: 1px solid #73007D;
    border-radius: 4px;
    padding: 0px 20px;
    margin-top: 10px;
    color: #73007D;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    cursor: pointer;
}

.tutorial_add_row_button:hover {
    border: 1px solid #FFFFFF;
    background-color: #73007D;
    color: #FFFFFF;
}

.persona-dept-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.selector-button {
    padding: 10px 15px;
    border: 1px solid #ccc;
    background-color: white;
    color: black;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    transition: all 0.3s ease;
}

.selector-button.selected {
    background-color: #F04A00;
    color: white;
    border: 1px solid #F04A00;
}

.selector:hover {
    background-color: #F04A00;
    color: white;
}

.radio-group {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
}

.radio-button {
    display: flex;
    align-items: center;
    font-size: 15px;
    gap: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: white;
    color: black;
}
/* 

.radio-button input {
    appearance: none; 
    width: 18px;
    height: 18px;
    border: 2px solid #F04A00;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.radio-button input:checked::before {
    content: "";
    width: 18px;
    height: 18px;
    background-color: #F04A00;
    border-radius: 50%;
    position: absolute;
}

.radio-button input:checked + span {
    background-color: #F04A00;
    color: white;
    border: 2px solid #F04A00;
    border-radius: 5px;
    padding: 8px 12px;
}

.radio-button:hover {
    color: #F04A00;
} */